<template>
    <div>
        <Preloader v-if='loader'/>
        <h4 class='mb-4 page-title'>Редактирование абонемента</h4>
        <div>
            <b-form class='g-1 p-4' @submit.prevent='submitForm' @reset.prevent='resetForm'>
                <div class='widget row'>

                    <b-form-group
                        id='input-group-1'
                        label='Название:'
                        class='col-6'
                        label-for='input-1'
                    >
                        <b-form-input
                            id='input-1'
                            v-model='form.title'
                            type='text'
                            placeholder='Название'
                            required
                        ></b-form-input>
                    </b-form-group>

                    <b-form-group
                        id="input-group-1"
                        label="Локация:"
                        class="col-6"
                        label-for="input-1"
                    >
                      <b-form-select
                          class=""
                          :options="locationList"
                          :value="form.location"
                          v-model="form.location"
                          required
                      ></b-form-select>
                    </b-form-group>

                    <b-form-group
                        id="input-group-1"
                        label="Тип:"
                        class="col-6"
                        label-for="input-1"
                    >
                        <b-form-select
                            class=""
                            :options="typeList"
                            :value="form.type"
                            v-model="form.type"
                            required
                        ></b-form-select>
                    </b-form-group>

                    <b-form-group
                        id="input-group-1"
                        label="Язык обучения:"
                        class="col-6"
                        label-for="input-1"
                    >
                        <b-form-select
                            class=""
                            :options="language_list"
                            :value="form.language"
                            v-model="form.language"
                            required
                        ></b-form-select>
                    </b-form-group>

                    <b-form-group
                        id='input-group-1'
                        label='Продолжительность (минут):'
                        class='col-6'
                        label-for='input-1'
                    >
                        <b-form-input
                            id='input-1'
                            v-model='form.duration'
                            type='number'
                            min='1'
                            placeholder='Продолжительность (минут)'
                            required
                        ></b-form-input>
                    </b-form-group>

                    <b-form-group
                        id='input-group-1'
                        label='Кол-во занятий:'
                        class='col-6'
                        label-for='input-1'
                    >
                        <b-form-input
                            id='input-1'
                            v-model='form.lesson_count'
                            type='number'
                            min='1'
                            placeholder='Кол-во занятий'
                            required
                        ></b-form-input>
                    </b-form-group>

                    <b-form-group
                        v-for="(curruncy, key) in currency_list"
                        :key="key"
                        id='input-group-1'
                        :label="curruncy"
                        class='col-6'
                        label-for='input-1'
                    >
                        <b-form-input
                            id='input-1'
                            v-model='form.prices[key]'
                            type='float'
                            min='1'
                            :placeholder='curruncy'
                        ></b-form-input>
                    </b-form-group>

                    <Switcher class='col-3' v-model='form.is_active' label='Опубликовано?'/>
                </div>
                <b-form-group class='row justify-content-end'>
                    <b-button type='submit' class='mr-2' variant='success'>Сохранить</b-button>
                    <b-button type='reset' variant='primary'>Назад</b-button>
                </b-form-group>
            </b-form>
        </div>
    </div>
</template>

<script>

import Preloader from '../../../components/Preloader';
import Switcher from '../../../components/Switcher';

export default {
    name: 'ProductCreate',
    components: { Preloader, Switcher },
    data() {
        return {
            loader: false,
            form: {
                title: null,
                lesson_count: null,
                is_active: true,
                language: 'ru',
                type: 0,
                location: null,
                duration: null,
                prices: {}
            },
            language_list: null,
            currency_list: null,
            typeList: null,
            locationList: null,
        };
    },
    computed: {},
    methods: {
        async fetchData() {
            try {
                this.loader = true;
                const { data } = await this.$axios.get(`crm/products/${this.$route.params.id}/edit`);

                for (const [key, val] of Object.entries(data.product)) {
                    this.form[key] = val;
                }

                this.language_list = data.language_list;
                this.currency_list = data.currency_list;
                this.locationList = data.location_list;
                this.typeList = { ...data.type_list };
                this.loader = false;
            } catch (ex) {
                this.loader = false;
                console.log('cant fetch data: ' + ex);
            }
        },
        async submitForm() {
            try {
                const {data} = await this.$axios.post(`crm/products/${this.$route.params.id}`, this.form, {
                    params: {
                        _method: 'patch',
                    },
                });
                this.$toasted.success("Запись обновлена", {position: "bottom-right"})
            } catch (e) {
                this.$toasted.error(e, {position: "bottom-right"})
            }
        },
        resetForm() {
            this.$router.back();
        },
    },
    async created() {
        await this.fetchData();
    },
};
</script>

<style lang='scss' scoped>
.btn .glyphicon {
    top: 0 !important;
}

.actions_container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.action {
    width: 100px;
}

.header-profile {
    width: 120px;
    text-align: center;
}

.button_add {
    display: inline-block;
    vertical-align: middle;
    border: none;
    font-weight: 400;
    background: #3E86CA;
    color: #fff;
    border-radius: 0.5rem;
    padding: 0 20px;
    font-size: 14px;
    height: 100%;
    transition: .1s;

    &:hover {
        background: #2e6ca7;
        text-decoration: none;
    }
}

@mixin badge($bg, $col) {
    background: $bg;
    color: $col;
    padding: 3px 8px;
    font-weight: 600;
    font-size: 10px;
    width: 100%;
    display: block;
    text-align: center;
    border-radius: 5px;
}

.badge {
    &-noprofile {
        @include badge(#ee8c62, #fff);
    }

    &-published {
        @include badge(#70dba6, #000);
    }

    &-edit {
        @include badge(#faed5e, #000);
    }
}

.page-title {
    font-weight: bold;
}
</style>